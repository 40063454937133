/* eslint-disable no-unused-vars */
/* eslint-disable @shein-aidc/common/notDirectUseCookie */

import UserKey from '../UserKey'
import { getCookie, setCookie, getQueryString } from '@shein/common-function'
import schttp from 'public/src/services/schttp'

const { default_currency, currencies } = gbCommonInfo
const COOKIE_KEY = 'currency'
const MAX_AGE = 60 * 60 * 24 * 30  //30天
const SUPPORT_CURRENCIES = Object.keys(currencies || {})

let hasReadCurrency = false
class Currency extends UserKey {
  static canHandle(key) {
    return key === 'currency'
  }

  get() {
    //由于cookie可能是其它站点设置，这里的cookie值必须为支持值，
    let value = default_currency
    //由于这里可能比较早执行，还没有请求到服务，cookie还是没被服务端验证过
    let cookieValue = getCookie(COOKIE_KEY)
    //cookie必须是当前站点支持值
    if( cookieValue && SUPPORT_CURRENCIES.includes(cookieValue) ){
      value = cookieValue 
    }

    //一般落地页如果携带query.currency，则node.js会返回set-cookie: currency
    //由于CDN的缓存，CDN侧不会返回set-cookie，缓存页面的url如果有query.currrency，这里需要在客户端设置cookie
    const queryCurrency = getQueryString({ key: 'currency' })

    //被CDN缓存的query.currency一般是与直出的gbCommonInfo.currency一致，确保queryCurrency值可信任
    //这里的gbCommonInfo.currency要取实时变量
    if( queryCurrency && queryCurrency != '0' && gbCommonInfo.currency == queryCurrency ){
      //但是当cookie中的currency和当前直出的currency不一致时，需要以query.currency为准

      //这里逻辑只需处理一次，hasReadCurrency，如果页面落地时没处理，后续也不应该再处理（globalCheck页面落地那里确保会执行一次）
      //不然异步请求改货币后会导致query.currency和cookie.currency不一致，导致这里再被执行
      if( !hasReadCurrency && queryCurrency != value ){
        const end = 60 * 60 * 24 * 30  //30天
        //这里的逻辑只需执行一次，即页面落地时
        value = queryCurrency

        setCookie({ key: COOKIE_KEY, value: value, curMainDomain: true, end })
        console.log('fix cdn query currency cookie')
      }
    }
    hasReadCurrency = true

    return value
  }
  async set(value, { isLog = 0 } = {}) {
    let params = { currency: value }
    if( isLog == 1 ){
      Object.assign(params, {
        isLog: 1,
        gbCur: gbCommonInfo.currency
      })
    }

    await schttp({
      url: '/api/common/currency/update',
      params
    })
    return value
  }
}

export default Currency
